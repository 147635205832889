.home-banner-shadow1 {
    width: 0px;
    height: 0px;
    box-shadow: 10px 10px 200px 100px #FFFFFF;
}

.home-banner-shadow2 {
    width: 0px;
    height: 0px;
    box-shadow: 1px 1px 300px 300px #0037FF40;
}

.home-steps-index::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
    backdrop-filter: blur(5px);
}