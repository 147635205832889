@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

html {
  background: #121018;
}

body {
  background: #121018;
}

@media (max-width: 1023px) {
  .header-nav-menu {
    position: fixed;
    background: #120f18;
    width: 100vw;
    height: calc(100vh - 72px);
    top: 72px;
    right: 0;
    color: white;
    padding: 8px 16px;
    font-size: 24px;
    line-height: 28px;
    overflow: hidden;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
  }
}

.header-nav-menu.active {
  pointer-events: auto;
  opacity: 1;
}

@media (max-width: 767px) {
  .header-nav-menu nav ul {
    gap: 24px;
  }
}

/* Hamburger Button */
.hamburger {
  padding: 0;
  width: 16px;
  height: 16px;
  border: none;
  z-index: 101;
}

.hamburger span {
  position: relative;
  display: block;
  width: 18px;
  height: 2px;
  margin-bottom: 5px;
  z-index: 1;
  background: white;
  transform-origin: 4px 0px;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

.hamburger span:first-child {
  transform-origin: 0% 0%;
}



.hamburger.active span {
  opacity: 1;
  background-color: white;
  transform: rotate(45deg) translate(-6px, -6px);
}

.hamburger.active span:nth-last-child(2) {
  transform: rotate(-45deg) translate(-6px, 6px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it is on the top layer */
  backdrop-filter: blur(10px);
  /* Apply blur effect to the background */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*==== scroll mouse ======*/
.scroll-down {
  color: white;
  margin-top: 100px;
  left: 0;
  right: 0;
}

.scroll-down:hover {
  color: white;

}

.container_mouse {
  margin-top: var(--pad-sec);
}

.container_mouse .mouse-btn {
  margin: 0px auto;
  width: 18px;
  height: 29px;
  border: 2px solid currentColor;
  border-radius: 30px;
  display: flex;
}

.container_mouse .mouse-btn .mouse-scroll {
  width: 4px;
  height: 6px;
  background: white;
  border-radius: 50%;
  margin: auto;
  animation: scrolling 1.5s linear infinite;
}


@keyframes scrolling {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(5px);
  }
}

.font-400-14-16 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.font-16-18 {
  font-size: 16px;
  line-height: 18px;
}
