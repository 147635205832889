@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-Thin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-ThinItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-ExtraLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-Light.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-LightItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-MediumItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-SemiBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-Bold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-BoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./fonts/AlbertSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Borna';
    src: url('./fonts/borna-medium-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
