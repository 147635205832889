.rule-table {
    border-spacing: 24px;
}
.rule-table th{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.7);
    padding: 0 24px;
    text-align: start;
}
.rule-table th:first-child{
    padding: 0 24px 0 0;
}
.rule-table td{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: white;
    padding: 0 24px 24px;
    vertical-align: middle;
    position: relative;
}
.rule-table td:first-child{
    
    padding: 0 24px 24px 0;
}